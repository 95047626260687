let appName = 'BookTok';

var u = navigator.userAgent;
var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
if (isiOS) {
    appName = 'BooksTok'
} else if (isAndroid) {
    appName = 'BookTok'
}

const privacyData = {
    'cn': [
        {
            'title': '',
            'content': [
                `${appName}非常重視用戶的隱私。當用戶登錄到我們的APP時，我們將記錄兩種類型的信息：`,
                `1.1統計信息，例如閱讀的書籍數量，章節數量，進度數量以及在書籍排名功能中使用的其他信息。有關此信息的統計信息包括記錄訪問${appName}的每個用戶的電話型號，系統版本和軟件版本。儘管此信息與個人無關，但我們可以使用它來幫助我們優化應用程序的用戶體驗並確定宣傳活動的有效性。`,
                `1.2當用戶使用某些${appName}功能（例如註冊${appName}帳戶）時，將要求他們提供手機號碼信息。註冊${appName}帳戶時，用戶可以選擇提供暱稱，性別，生日，位置和其他信息。 ${appName}收集此類個人信息主要是為了使用戶使用我們的服務更輕鬆，更令人滿意。 ${appName}的目標是為所有Internet用戶提供一個愉快，積極和和諧的在線體驗。這些個人信息有助於我們實現這一目標。`,
                `保護用戶隱私（尤其是未成年人）是${appName}的一項基本政策。 ${appName}將嚴格管理和保護用戶提供的信息，並使用相應的技術防止用戶個人數據的丟失，被盜或濫用，當用戶使用外部網絡服務或第三方時，非公開註冊信息和內容將存儲在${appName}中。發生以下情況時，${appName}將披露個人信息：`,
                '1.您授權或同意披露；',
                '2.在緊急情況下，為了保護用戶的合法權益或安全和公共利益；',
                '3.根據法律或主管當局的要求提供您的個人信息；',
                `${appName}鼓勵用戶更新和修改其個人信息以使其可用。用戶可以自行決定修改和刪除相關文檔。請注意，任何時候您自願披露個人信息（例如在社區中，通過評論或通過電子郵件），此類信息可能會被其他人收集和使用，從而導致您的個人信息被披露，${appName}不對您承擔任何責任。如果您的個人信息是通過上述渠道披露的，則可能導致您的個人信息被披露。因此，我們提醒並請您仔細考慮是否有必要在上述渠道中披露您的個人信息。您全權負責對您的帳戶密碼信息保密。在任何情況下，請注意發送，不要讓其他人隨意使用它。`
            ]
        }
    ],
    'yn': [
        {
            'title': '',
            'content': [
                `${appName} rất coi trọng việc bảo mật thông tin cá nhân của người dùng. Khi người dùng đăng nhập APP chúng tôi, chúng tôi sẽ ghi lại hai loại thông tin:`,
                `1.1 Thống kê như số lượng đọc sách , số lượng theo dõi, số lượng chia sẻ và thông tin khác được sử dụng trong chức năng xếp hạng sách. Thống kê về thông tin này liên quan đến việc ghi lại kiểu điện thoại, phiên bản hệ thống và phiên bản phần mềm của mỗi người dùng truy cập ${appName}. Mặc dù thông tin này không liên quan cá nhân, nhưng chúng tôi có thể sử dụng nó để giúp chúng tôi tối ưu hóa trải nghiệm người dùng của ứng dụng và xác định tính hiệu quả của các hoạt động truyền bá của chúng tôi.`,
                `1.2 Khi người dùng sử dụng một số tính năng của ${appName}, chẳng hạn như đăng ký tài khoản ${appName}, họ sẽ được yêu cầu cung cấp thông tin số điện thoại di động. Khi đăng ký tài khoản ${appName} , người dùng có thể tùy chọn cung cấp biệt danh, giới tính, sinh nhật, địa điểm và các thông tin khác. ${appName} thu thập loại thông tin cá nhân này chủ yếu để người dùng sử dụng dịch vụ của chúng tôi dễ dàng và thỏa đáng hơn. Mục tiêu của ${appName} là cung cấp cho tất cả mọi người dùng Internet trải nghiệm trực tuyến thú vị, tích cực và hài hòa. Và thông tin cá nhân này giúp chúng tôi đạt được mục tiêu này.`,
                `Bảo vệ quyền riêng tư của người dùng (đặc biệt là trẻ chưa thành niên) là một chính sách cơ bản của ${appName}. ${appName} sẽ quản lý và bảo vệ nghiêm ngặt thông tin do người dùng cung cấp,và sử dụng các công nghệ tương ứng để ngăn ngừa mất, đánh cắp hoặc lạm dụng dữ liệu cá nhân của người dùng.Đảm bảo rằng nó sẽ không đơn phương cung cấp thông tin đăng ký và nội dungkhông công khai được lưu trữ trong ${appName} khi người dùng sử dụng dịch vụ mạng cho bên ngoài hoặc bên thứ ba nào.${appName} sẽ tiết lộ thông tin cá nhận khi xảy ra các tình huống sau:`,
                '1. Bạn cho phép hoặc đồng ý tiết lộ;',
                '2. Trong trường hợp khẩn cấp, để bảo vệ các quyền hợp pháp của người dùng hoặc an toàn và lợi ích công cộng;',
                '3. Cung cấp thông tin cá nhân của bạn theo quy định của pháp luật hoặc yêu cầu của cơ quan có thẩm quyền;',
                `${appName} khuyến khích người dùng cập nhật và sửa đổi thông tin cá nhân của họ để làm cho nó có hiệu lực. Người dùng có thể sửa đổi và xóa các tài liệu liên quan theo ý của họ. Xin lưu ý rằng bất cứ khi nào bạn tự nguyện tiết lộ thông tin cá nhân (như trong cộng đồng, bình luận hoặc qua e-mail), những thông tin đó có thể được người khác thu thập và sử dụng, do đó khiến thông tin cá nhân của bạn bị tiết lộ, ${appName} sẽ không chịu trách nhiệm nếu bạn,nếu thông tin cá nhân được công bố trên các kênh trên, bạn có thể khiến thông tin cá nhân bị tiết lộ. Do đó, chúng tôi nhắc nhở và yêu cầu bạn xem xét cẩn thận có cần thiết phải tiết lộ thông tin cá nhân của bạn trong các kênh trên hay không. Bạn là người duy nhất chịu trách nhiệm giữ bí mật thông tin mật khẩu tài khoản của bạn. Trong mọi trường hợp, vui lòng chú ý gữi và không cho người khác sử dụng theo ý muốn.`
            ]
        }
    ],
    'en': [
        {
            'title': '',
            'content': [
                `${appName} attaches great importance to user privacy. When a user logs in to our APP, we will record two types of information:`,
                `1.1 Statistical information, such as the number of books read, the number of chapters, the number of progress, and other information used in the book ranking function. Statistics about this information include the phone model, system version, and software version of each user who visits ${appName}. Although this information has nothing to do with individuals, we can use it to help us optimize the user experience of the application and determine the effectiveness of promotional activities.`,
                `1.2 When users use certain ${appName} functions (such as registering a ${appName} account), they will be asked to provide mobile phone number information. When registering a ${appName} account, users can choose to provide nickname, gender, birthday, location and other information. ${appName} collects such personal information mainly to make it easier and more satisfying for users to use our services. The goal of ${appName} is to provide a pleasant, positive and harmonious online experience for all Internet users. This personal information helps us achieve this goal.`,
                `Protecting user privacy (especially minors) is a basic policy of ${appName}. ${appName} will strictly manage and protect the information provided by users, and use corresponding technologies to prevent the loss, theft or abuse of users’ personal data. When users use external network services or third parties, non-public registration information and content will be stored in ${appName}. . When the following situations occur, ${appName} will disclose personal information:`,
                '1.You authorize or agree to the disclosure;',
                '2.In an emergency, in order to protect the legitimate rights and interests or safety and public interest of users;',
                '3.Provide your personal information in accordance with the requirements of the law or the competent authority;',
                `${appName} encourages users to update and modify their personal information to make it available. Users can modify and delete related documents at their own discretion. Please note that any time you voluntarily disclose personal information (for example, in the community, through comments or via email), such information may be collected and used by others, resulting in the disclosure of your personal information. ${appName} will not bear any responsibility for you. responsibility. If your personal information is disclosed through the above channels, it may result in your personal information being disclosed. Therefore, we remind and ask you to carefully consider whether it is necessary to disclose your personal information in the above channels. You are solely responsible for keeping your account password information confidential. In any case, please pay attention to sending it and don't let others use it at will.`
            ]
        }
    ]
};

export { privacyData };
